import { Box, Button, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Slide, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import '../DonorSignupForm/donorsignupform.css';
import axios from 'axios';
import { primaryColor } from '../../Styles/styles';
import { useDispatch } from 'react-redux';
import { handleHousingSocietyModal, handleThankYouModal } from '../../redux';
import { useSelector } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import close from '../../assets/svg/close.svg';

const HousingSocietyForm = () => {
    
    const store: any = useSelector((store: any) => store.handleHousingSocietyModal);
    const dispatch = useDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Formbody = () => {
        const initialValues = {
            name: '',
            personName: '',
            role: '',
            email: '',
            phoneNumber: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipcode: '',
            selectedDate: '',
            communicate: '',
            notes: '',
        };
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const validationSchema = Yup.object().shape({
            name: Yup.string().required('Full Name is required'),
            email: Yup.string()
                .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    'Invalid email'
                )
                .required('Email is required'),
            personName: Yup.string().required('Person Name is required'),
            role: Yup.string().required('Role is required'),
            phoneNumber: Yup.string().matches(/^[0-9]{10}$/, 'Invalid mobile number format').required('Phone Number is required'),
            addressLine1: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            addressLine2: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            city: Yup.string().max(50, 'City name must be at most 100 characters').required('City is required'),
            state: Yup.string().max(50, 'State name must be at most 50 characters').required('State is required'),
            zipcode: Yup.string().length(6, 'Zip code must be of 6 characters').matches(/^\d+$/, 'Zip code must contain only numbers').required('Zip code is required'),
            selectedDate: Yup.date().min(today, 'Selected date must be today or a future date').required('Preferred Pickup Date is required'),
            communicate: Yup.string().required('Please select an option'),
            notes: Yup.string().max(500, 'Max 100 characters')

        });

        const handleSubmit = async (values: any) => {
            try {
                const formdata = {
                    "entry.1868576959": values.name,
                    "entry.852882433": values.personName,
                    "entry.1987299253": values.role,
                    "entry.463141716": values.email,
                    "entry.1690252720": values.phoneNumber,
                    "entry.889721906": values.addressLine1,
                    "entry.487298562": values.addressLine2,
                    "entry.41358183": values.city,
                    "entry.1554309821": values.state,
                    "entry.397432870": values.zipcode,
                    "entry.1796739843": values.selectedDate,
                    "entry.229437923": values.communicate,
                    "entry.1177838916": values.notes,
                }

                await axios.post(`https://cricketspin.rewardzpromo.com/api/submitForm/HousingSocietySignup`, formdata)
                dispatch(handleHousingSocietyModal(false))
                dispatch(handleThankYouModal({ state: true, formName: 'housingSociety' }))
            } catch (error) {
                console.log("🚀 ~ handleSubmit ~ error:", error)
            }
        }
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (

                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container className='form-donate-now' sx={{ width: isMobile ? '100%' : '942px' }}>
                            <div style={{ width: '100%' }}>
                                <div className='form-footer-header'>
                                    Housing Society <br/> Signup
                                    <img src={close} alt="logo" onClick={handleClose} height={'25px'} width={'25px'} />
                                </div>
                                <div className='form-horizontal-line' />

                                <div className='form-message'>We're excited that your housing society is interested in collaborating with CYC to host a collection drive. Your support is invaluable in helping us reach our goals. Please fill in the details below to get started.</div>
                            </div>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Society Name
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="name" />}

                                // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Email Address
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="email" />}

                                // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Contact Person Name
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="personName"
                                    name="personName"
                                    value={formik.values.personName}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="personName" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Contact Person's Role/Position
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="role"
                                    name="role"
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="role" />}

                                // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Phone Number
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="phoneNumber" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Society Address Line 1
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine1"
                                    name="addressLine1"
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine1" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Society Address Line 2
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine2"
                                    name="addressLine2"
                                    value={formik.values.addressLine2}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine2" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    City
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="city"
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="city" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    State
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="state"
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="state" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Zip Code
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="zipcode"
                                    name="zipcode"
                                    value={formik.values.zipcode}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="zipcode" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                    Preferred Pickup Date
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '355px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="selectedDate"
                                    name="selectedDate"
                                    type="date"
                                    value={formik.values.selectedDate}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="selectedDate" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <div className='form-content'>
                                    Are you able to facilitate communication about the drive within your society ?
                                </div>
                                <FormControl fullWidth error={Boolean(formik.touched.communicate && formik.errors.communicate)}>

                                    <RadioGroup
                                        id="communicate"
                                        name="communicate"
                                        value={formik.values.communicate}
                                        onChange={formik.handleChange}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    {formik.touched.communicate && formik.errors.communicate ? (
                                        <Typography style={{ fontSize: '12px', margin: 2 }} sx={{ color: 'red' }}>
                                            {formik.errors.communicate}
                                        </Typography>
                                    ) : null}
                                </FormControl>

                            </Grid>

                            <Grid item xs={12}>
                                <div className='form-content'>
                                    Any additional information or requirements you would like to share ?
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ height: '170px', marginBottom: isMobile? '30%' : '10%', marginTop: '2%', width: isMobile ? '355px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="notes"
                                    name="notes"
                                    multiline
                                    rows={6}
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="notes" />}
                                    fullWidth
                                />
                                <div className='box-button'>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            height: '55px',
                                            width: '187px',
                                            fontSize: '16px',
                                            marginLeft: isMobile ? '100px' : '300px',
                                            backgroundColor: primaryColor,
                                            ":hover": { backgroundColor: primaryColor },
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>

                            {/* <Button color="primary" variant="contained" type="submit" sx={{ marginLeft: isMobile ? '80px' : '300px', textAlign: 'center', backgroundColor: primaryColor, borderColor: primaryColor, ":hover": { backgroundColor: primaryColor }, height: '55px', width: '187px', fontSize: '16px' }}>
                                Submit
                            </Button> */}
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    };

    const handleClose = () => {
        dispatch(handleHousingSocietyModal(false))
    };

    return (
        <Modal
            open={store}
            onClose={() => dispatch(handleHousingSocietyModal(false))}
            style={{ marginTop: isMobile ? '20%' : '5vh' }}
        >
            <Slide direction="up" in={store} mountOnEnter unmountOnExit>
                <Box
                    className="modal"
                    sx={{
                        borderRadius: '8px',
                    }}
                >
                    <Formbody />
                </Box>
            </Slide>
        </Modal>
    )
}

export default HousingSocietyForm
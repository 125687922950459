import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { handleSignupModal } from '../../redux';
import homebanner1 from '../../assets/images/homebanner1.png';
import MobHomeBanner from '../../assets/MobileImages/MobHomeBanner.png';
import MobThreeRBg from '../../assets/MobileImages/MobThreeRBg.png';
import ThreeRBg from '../../assets/images/help-many-bg.png';
import helpus1 from '../../assets/images/helpus1.png';
import teen_indian_volunteer from '../../assets/images/teen_indian_volunteer.png';
import helpus2 from '../../assets/images/helpus2.png';
import helpus3 from '../../assets/images/helpus3.png';
import gift from '../../assets/svg/giftOrange.svg';
import volunteerround from '../../assets/svg/volunteerround.svg';
import disableGift from '../../assets/svg/disableGift.svg';
import volunteerdisabled from '../../assets/svg/volunteerdisabled.svg';
import mbProfile from '../../assets/svg/mbProfile.svg';
import profileIconWomen from '../../assets/svg/profileIconWomen.svg';
import broadcast from '../../assets/svg/broadcast.svg';
import './home.css';
import { primaryColor } from '../../Styles/styles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountUp from 'react-countup';
import { useIsVisible } from '../../useIsVisible';

type ReviewCardProps = {
  name: string;
  text: string;
};

const Home = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);
  const [statsRendered, setStatsRendered] = useState(false)

  const cards = [
    {
      id: 0,
      title: 'Organize Collection drives !',
      description: "Host a collection drive in your housing society. We'll provide the boxes and posters, and help you set everything up.",
      image: helpus1,
      activeIcon: gift,
      disabledIcon: disableGift,
    },
    {
      id: 1,
      title: 'Become Change champions',
      description: "You'll organize collection drives, engage local communities, and become a key figure in promoting our cause.",
      image: helpus2,
      activeIcon: volunteerround,
      disabledIcon: volunteerdisabled,
    },
    {
      id: 2,
      title: 'Corporates - Make it part of CSR !',
      description: "Corporate involvement is vital to expanding our reach and enhancing our impact. By partnering with CYC, your company can serve as a collection point, aiding in the gathering and distribution of clothing donations.",
      image: helpus3,
      activeIcon: gift,
      disabledIcon: broadcast,
    },
  ];

  const [currentCard, setCurrentCard] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCard((prevCard) => (prevCard + 1) % cards.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(()=>{
    if(!isVisible) return
    if(statsRendered) return
    setStatsRendered(true)
  },[isVisible])

  const testimonials = [
    {
      name: "Eela Bhan",
      text: "Attending Clean Your Closet's donation camp in our building was a great opportunity to declutter while making a positive impact. Seeing my neighbors come together to donate their pre-loved clothing was inspiring. It's heartening to be part of a community that cares.",
    },
    {
      name: "Doreen Patrao",
      text: "Participating in Clean Your Closet's donation camp at our office was a rewarding experience. It not only encouraged team bonding but also allowed us to give back to the community in a tangible way. I'm proud of our office's collective effort to support such a worthy cause.",
    },
    {
      name: "Sheetal Somaiya",
      text: "As a busy professional, I appreciate the ease of Clean Your Closet's doorstep pickup service. Their team was punctual and courteous, making the donation process seamless. I'm glad to have contributed to such a meaningful initiative without disrupting my schedule.",
    },
    {
      name: "Veena Patel",
      text: "Using Clean Your Closet's doorstep pickup service was incredibly convenient. I simply filled out the form, and they arrived right at my door to collect my clothing donations. Knowing that my pre-loved items will now benefit someone in need gives me a sense of fulfillment.",
    },
  ];


  const ReviewCard: React.FC<ReviewCardProps> = ({ name, text }) => {
    return (
      <Card className='review-card' sx={{ boxShadow: 0, width: '280px', margin: '10px' }}>
        <Box className='review-card-icon' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Avatar
            src={ name==='Doreen Patrao' ? mbProfile : profileIconWomen}
            sx={{
              height: '46px',
              width: '46px',
              backgroundColor: '#FFFFFF',
              borderRadius: '50%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              marginTop: '4%',
            }}
          />
          <Typography className='main-text' sx={{ fontSize: '16px', lineHeight: '20.48px', fontFamily: 'Lora', marginTop: '8%', marginLeft: '10px', fontWeight: '700', color: '#666666' }}>
            {name}
          </Typography>
        </Box>
        <CardContent sx={{ padding: '8px 0 0 0' }}>
          <Typography className='desc-text' sx={{ marginTop: '5%', fontSize: '14px', color: '#000' }}>
            {text}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <div className="responsive-image-container">
        {/* For banner */}
        <img src={ isMobile ? MobHomeBanner: homebanner1} alt="Background" className="responsive-image" loading="lazy"/>
        <div className="overlay">
          <div className="home-container">
            <div className="overlay">
              <div className="text-center">
                <span className="main-text">#Give, Donate, Share</span>
                <div className="content-text">
                  Give your preloved clothing to those in need and make a difference!
                </div>
                <Button onClick={() => dispatch(handleSignupModal(true))} size='large' variant="contained" sx={{ backgroundColor: primaryColor, marginTop: '5%', ":hover": { backgroundColor: primaryColor }, height: '55px', width: '165px' }}>Give Now</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Welcome to clean your closet */}
      <div className='home-content'>
        <div className='home-header'>
          <span >Welcome <span className='highlight-web-text'> To </span> </span>
          <span className='highlight-mobile-text'>Clean Your Closet</span>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div className="home-horizontal-line" style={{ marginLeft: isMobile ? '15px auto' : 0 }} />
          </div>
        </div>

        <div className="desc-text" style={{ textAlign: 'center', marginTop: '2%' }}>
          Creating a world where every piece of clothing gets a second chance.
        </div>
        <div className='home-card'>
          <div className='card-desc'>
            <div className='gift-us-bg' />
            <div className='header-div'>
              {!isMobile && <div className='header-number'>01</div>}
              <div className='card-content' style={{ width: '70%' }}>Doorstep Pick up </div>
            </div>
            <div className='home-card-header' >Donate Now</div>
            <div className='card-content'>With our easy home pickup service, donating has never been simpler. Sign up, and we'll arrange a doorstep pickup at your convenience.</div>
          </div>
          <div className='card-desc'>
            <div className='joins-us-bg' />
            <div className='header-div'>
              {!isMobile && <div className='header-number'>02</div>}
              <div className='card-content' style={{ width: '70%' }}>Become a Change Champion </div>
            </div>
            <div className='home-card-header' >Join Us</div>
            <div className='card-content'>Become a Change Champion with CYC and take the lead in your area or zone. By collaborating with our team, you'll organize collection drives, engage local communities, and become a key figure in promoting our cause.</div>
          </div>
          <div className='card-desc'>
            <div className='mic-bg' />
            <div className='header-div'>
              {!isMobile && <div className='header-number'>03</div>}
              <div className='card-content' style={{ width: '90%' }}>Help us reach the clothing to the needy </div>
            </div>
            <div className='home-card-header' >Bring the change</div>
            <div className='card-content'>At CYC, we are eager to broaden our network of NGO partners across India. We are looking for organizations that not only distribute clothing to those in need but are also involved in recycling or repurposing clothes</div>
          </div>
        </div>
        {/* <Button variant="contained" sx={{ height:'55px',width:'191px', backgroundColor: primaryColor, marginTop: '5%', ":hover": { backgroundColor: primaryColor },fontSize:'16px',fontFamily:'Roboto',fontWeight:'400' }}>SEE ALL CAUSE</Button> */}

      </div>

      {/* Stats */}
      <div className='stats'>
        <div className='stats-card'>
          <div className='white-bg' />
          <div className='stats-data'>
            <div className='stats-number'>{ statsRendered ? <CountUp duration={3} end={15300}/> : <p>15,300</p>}</div>
            <div className='stats-text'>Clothing items Given</div>
          </div>
        </div>
        <div className='stats-card'>
          <div className='team-bg' />
          <div className='stats-data'>
            <div className='stats-number'>{ statsRendered ? <CountUp duration={3} end={654}/> : <p>654</p>}</div>
            <div className='stats-text'>Donors</div>
          </div>
        </div>
        <div className='stats-card'>
          <div className='volunteer-bg' />
          <div className='stats-data'>
            <div className='stats-number' ref={ref}>{ statsRendered ? <CountUp duration={3} end={23}/> : <p>23</p>}</div>
            <div className='stats-text'>Partners</div>
          </div>
        </div>
      </div>

      {/* Help us */}
      {
        isMobile ? (
          <Box className="card-slider">
            <div className="card-slider-card">
              <CardContent>
                <span className="card-slider-title">
                  How To Help Us
                </span>
                <div className='home-horizontal-line' />
                <span className="card-slider-description">
                  To help us, you can donate clothing items or volunteer your time to support our cause. Every contribution makes a meaningful difference in the lives of those in need.
                </span>
                <Box component="img" src={cards[currentCard].image} alt="Card image" className="card-slider-image" />
              </CardContent>
              <Box className="icons-container">

                <Box
                  component="img"
                  src={currentCard === 0 ? cards[0]['activeIcon'] : cards[0]['disabledIcon']}
                  alt={`Icon`}
                  className="card-slider-icon"
                  onClick={() => setCurrentCard(0)}
                />
                <Box
                  component="img"
                  src={currentCard === 1 ? cards[0]['activeIcon'] : cards[0]['disabledIcon']}
                  alt={`Icon`}
                  className="card-slider-icon"
                  onClick={() => setCurrentCard(1)}
                />
                <Box
                  component="img"
                  src={currentCard === 2 ? cards[0]['activeIcon'] : cards[0]['disabledIcon']}
                  alt={`Icon`}
                  className="card-slider-icon"
                  onClick={() => setCurrentCard(2)}
                />
              </Box>

              <span className="card-slider-header">
                {cards[currentCard].title}
              </span><br />
              <br />
              <span className="card-slider-description">
                {cards[currentCard].description}
              </span>
            </div>
          </Box>
        ) : (
          <div className='help-us'>
            <img src={teen_indian_volunteer} height={'855px'} width={'550px'} alt='teen_indian_volunteer' />
            <div>
              <div className='help-us-header'>
                <span >How To</span>
                <span className="main-text"> Help </span>
                <span >Us</span>
              </div>
              <div className='horizontal-line' />
              <div className='help-us-desc'>
                To help us, you can donate clothing items or volunteer your time to support our cause. Every contribution makes a meaningful difference in the lives of those in need.
              </div>
              <div className='help-us-data'>
                <div className='gift-us-bg' style={{ marginLeft: 0 }} />
                <div style={{ flexDirection: 'column', marginTop: '10px', position: 'relative' }}>
                  <div className='header-number' style={{ marginLeft: '35px', marginTop: '-18px', position: 'absolute' }}>01</div>
                  <div className='home-card-header' style={{ marginLeft: '35px', justifyContent: 'start', position: 'relative' }}>Organize Collection drives !</div>
                  <div className='help-us-desc' style={{ marginTop: '3%', marginLeft: '35px', position: 'relative' }}>Host a collection drive in your housing society. We'll provide the boxes and posters, and help you set everything up.</div>
                </div>
              </div>
              <div className='help-us-data'>
                <div className='volunteer-orange-bg' style={{ marginLeft: 0, }} />
                <div style={{ flexDirection: 'column', marginTop: '10px' }}>
                  <div className='header-number' style={{ marginLeft: '35px', marginTop: '-18px', position: 'absolute' }}>02</div>
                  <div className='home-card-header' style={{ marginLeft: '35px', justifyContent: 'start' }}>Become Change champions</div>
                  <div className='help-us-desc' style={{ marginTop: '3%', marginLeft: '35px' }}>You'll organize collection drives, engage local communities, and become a key figure in promoting our cause.</div>
                </div>

              </div>
              <div className='help-us-data'>
                <div className='mic-bg' style={{ marginLeft: 0, }} />
                <div style={{ flexDirection: 'column', marginTop: '10px' }}>
                  <div className='header-number' style={{ marginLeft: '35px', marginTop: '-18px', position: 'absolute' }}>03</div>
                  <div className='home-card-header' style={{ marginLeft: '35px', justifyContent: 'start' }}>Corporates - Make it part of CSR !</div>
                  <div className='help-us-desc' style={{ marginTop: '3%', marginLeft: '35px' }}>Corporate involvement is vital to expanding our reach and enhancing our impact. By partnering with CYC, your company can serve as a collection point, aiding in the gathering and distribution of clothing donations.</div>
                </div>

              </div>
            </div>
          </div>
        )
      }
      <br /><br />
      {/* For banner */}
      <div className="three-rs-container">
        <img src={ isMobile ? MobThreeRBg : ThreeRBg} alt="Background" className="responsive-image" />
        <div className="overlay-r">
          <div className='help-us-header' style={{ color: primaryColor }}>
            Reduce Reuse Recyle
          </div>
          <div className='sub-text' style={{ maxWidth: isMobile ? '288px' : '100%', fontSize: isMobile ? '16px' : '35px' }}>
            92 million tonnes of textiles waste is produced every year
          </div>
          <div className='help-many-desc' style={{ maxWidth: isMobile ? '288px' : '100%' }}>
            To put things in perspective, this means that the equivalent of a rubbish truck full of clothes ends up on landfill sites every second
          </div>
        </div>
      </div>

      {/* Reviews */}
      <br />
      <div className='reviews'>
        <div className='help-us-header'>
          <span >Our</span>
          <span className="main-text"> Donors </span>
          <span >Says</span>
        </div>
        <div className='horizontal-line' />
        <br />
        {
          !isMobile &&  (
            <Grid container spacing={2} mt={1} justifyContent="center" width={'135vh'} >
              {testimonials.map((testimonial: any, index) =>
                <Grid item xs={12} sm={6} key={index}>
                  <Card elevation={0} sx={{
                    display: 'flex',
                    border: 'none',
                  }}>
                    <CardMedia
                      sx={{
                        width: '120px',
                        height: '120px',
                        backgroundSize: 'cover',
                        marginLeft: '9%'
                      }}
                      image={profileIconWomen}
                      title="Donation Drive"
                    />
                    <CardContent sx={{
                      flex: 1,
                    }}>
                      <Grid container spacing={2} ml={1}>
                        <Grid item xs={12}>
                          <Typography variant="body2" component="p" sx={{ fontFamily: 'Roboto', fontSize: '16px', textAlign: 'start', color: '#666666' }}>
                            {testimonial.text}
                          </Typography>
                          <Typography variant="body1" component="p" sx={{ fontSize: '21px', lineHeight: '43px', fontFamily: 'Lora', marginTop: '2%', marginLeft: '30px', fontWeight: '700', color: primaryColor }}>
                            - {testimonial.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          )
        }
      </div>
      {isMobile &&
          <div className='review-slider'>
          {testimonials.map((review, index) => (
            <div className='review-card-wrapper' key={index}>
              <ReviewCard name={review.name} text={review.text} />
            </div>
          ))}
        </div>
        }
      <br/>
    </>
  );
};

export default Home;

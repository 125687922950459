import { Box, Button, Grid, Modal, Slide, TextField, useMediaQuery, useTheme } from '@mui/material';
import '../DonorSignupForm/donorsignupform.css';
import axios from 'axios';
import { primaryColor } from '../../Styles/styles';
import { useDispatch } from 'react-redux';
import { handleJoinUsModal, handleThankYouModal } from '../../redux';
import { useSelector } from 'react-redux';
import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import close from '../../assets/svg/close.svg';

const JoinUsForm = () => {

    const store: any = useSelector((store: any) => store.handleJoinUsModal);
    const dispatch = useDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const Formbody = () => {
        const initialValues = {
            name: '',
            phoneNumber: '',
            age: '',
            notes: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            state: '',
            city: '',
            zipcode: '',
            area: '',
            previousExperience: '',
            reasonForCYC: '',
            organizeDesc: ''
        };

        const validationSchema = Yup.object().shape({
            name: Yup.string().required('Full Name is required'),
            age: Yup.number().integer('Age must be an integer'),
            email: Yup.string()
                .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    'Invalid email'
                )
                .required('Email is required'),
            phoneNumber: Yup.string().matches(/^[0-9]{10}$/, 'Invalid mobile number format').required('Phone Number is required'),
            addressLine1: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            addressLine2: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            city: Yup.string().max(50, 'City name must be at most 100 characters').required('This field is required'),
            state: Yup.string().max(50, 'State name must be at most 50 characters').required('This field is required'),
            zipcode: Yup.string().length(6, 'Zip code must be of 6 characters').matches(/^\d+$/, 'Zip code must contain only numbers').required('Zip code is required'),
            area: Yup.string().max(50, 'Area name must be at most 50 characters').required('This field is required'),
            previousExperience: Yup.string().max(500, 'Max 500 characters'),
            reasonForCYC: Yup.string().max(500, 'Area name must be at most 50 characters').required('This field is required'),
            organizeDesc: Yup.string().max(500, 'Area name must be at most 50 characters').required('This field is required'),
            notes: Yup.string().max(500, 'Area name must be at most 50 characters')

            // Add validation for other fields here
        });

        const handleSubmit = async (values: any) => {
            try {
                const formdata = {
                    "entry.1772898576": values.name,
                    "entry.1113824692": values.age,
                    "entry.1168521537": values.email,
                    "entry.1768480444": values.phoneNumber,
                    "entry.1941237761": values.addressLine1,
                    "entry.527013477": values.addressLine2,
                    "entry.1830051799": values.city,
                    "entry.1512997099": values.state,
                    "entry.557651127": values.zipcode,
                    "entry.1743390682": values.area,
                    "entry.1358409462": values.previousExperience,
                    "entry.639946377": values.reasonForCYC,
                    "entry.1286980905": values.organizeDesc,
                    "entry.1010697820": values.notes
                }

                await axios.post(`https://cricketspin.rewardzpromo.com/api/submitForm/joinus`, formdata)
                dispatch(handleJoinUsModal(false))
                dispatch(handleThankYouModal({ state: true, formName: 'joinus' }))
            } catch (error) {
                console.log("🚀 ~ handleSubmit ~ error:", error)
            }
        }

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (

                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container className='form-donate-now' sx={{ width: isMobile ? '100%' : '942px' }}>
                            <div style={{ width: '100%' }}>
                                <div className='form-footer-header'>
                                    Join Us
                                    <img src={close} alt="logo" onClick={handleClose} />
                                </div>
                                <div className='form-horizontal-line' />

                                <div className='form-message'>Thank you for your interest in becoming a Change Champion for CYC! Your leadership and enthusiasm can drive significant impact in your community. Please fill out the form below to get started on this rewarding journey.</div>
                            </div>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Full Name
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="name" />}

                                // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Email Address
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    type='email'
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="email" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Phone Number
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="phoneNumber" />}

                                    // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Age (if applicable)
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="age"
                                    name="age"
                                    value={formik.values.age}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="age" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Address Line 1
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine1"
                                    name="addressLine1"
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine1" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Address Line 2
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine2"
                                    name="addressLine2"
                                    value={formik.values.addressLine2}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine2" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    State
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="state"
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="state" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    City
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="city"
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="city" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Zip Code
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="zipcode"
                                    name="zipcode"
                                    value={formik.values.zipcode}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="zipcode" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                Which area or zone do you wish to represent as a Change Champion ?
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="area"
                                    name="area"
                                    value={formik.values.area}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="area" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                Previous Volunteering Experience ? <span style={{ fontStyle: 'italic' }}>( optional )</span>
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="previousExperience"
                                    name="previousExperience"
                                    value={formik.values.previousExperience}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="previousExperience" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                Why do you want to become a Change Champion for CYC ?
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="reasonForCYC"
                                    name="reasonForCYC"
                                    value={formik.values.reasonForCYC}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="reasonForCYC" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                Are you able to lead and organize collection drives in your area ?
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="organizeDesc"
                                    name="organizeDesc"
                                    value={formik.values.organizeDesc}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="organizeDesc" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                    Any additional information you would like to share ? <span style={{ fontStyle: 'italic' }}>( optional )</span>
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%',marginBottom: isMobile? '30%' : '7%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="notes"
                                    name="notes"
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="notes" />}
                                    fullWidth
                                />
                                <div className='box-button'>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            height: '55px',
                                            width: '187px',
                                            fontSize: '16px',
                                            marginLeft: isMobile ? '100px' : '300px',
                                            backgroundColor: primaryColor,
                                            ":hover": { backgroundColor: primaryColor },
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    };

    const handleClose = () => {
        dispatch(handleJoinUsModal(false))
    };

    return (
        <Modal
            open={store}
            onClose={() => dispatch(handleJoinUsModal(false))}
            style={{ marginTop: isMobile ? '20%' : '5vh' }}
        >
            <Slide direction="up" in={store} mountOnEnter unmountOnExit>
                <Box
                    className="modal"
                    sx={{
                        borderRadius: '8px',
                    }}
                >
                    <Formbody />
                </Box>
            </Slide>
        </Modal>
    )
}

export default JoinUsForm
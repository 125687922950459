import { useState } from 'react';
import { Box, Container, Grid, Typography, Button, useMediaQuery, useTheme, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/svg/Clean Your Closet.png';
import mailIcon from '../../assets/svg/mail-orange.svg';
import phoneIcon from '../../assets/svg/phoneIconOrange.svg';
import { handleSignupModal } from '../../redux';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/EastSharp';
import MailIcon from '@mui/icons-material/Mail';
import { primaryColor } from '../../Styles/styles';

const useStyles = makeStyles({
  fullList: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  menuIcon: {
    position: 'absolute',
    top: '10px',
    left: '10px',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  donateButton: {
    width: '100%',
    margin: '16px 0',
    backgroundColor: '#f56c00',
    color: '#fff',
  },
  contactInfo: {
    padding: '16px',
    textAlign: 'center',
    marginTop: '16px',
  },
  contactDetails: {
    display: 'flex',
    justifyContent: 'center',
    gap: '50px',
  }
});
const ContactBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  // const [expand, setExpand] = useState(false)
  const toggleDrawer = (open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItemsMobile = [
    { name: 'Home', path: '/' },
    {
      name: 'About Us', path: '/about'
    },
    {
      name: 'About Founder', path: '/aboutowner'
    },
    // { name: 'Causes', path: '/' },
    { name: 'Change Champions', path: '/changechampions' },
    { name: 'Get Involved', path: '/getInvolve' },
    { name: 'Partners', path: '/partner' },
    { name: 'Gallery', path: '/pages' },
    { name: 'Contact', path: '/contactus' }
  ];

  // const handleAboutClick = (event: { stopPropagation: () => void; }) => {
  //   event.stopPropagation(); // Prevents the event from bubbling up to the parent
  //   setExpand(!expand);
  // };
  return (
    <Box py={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',height: isMobile ? 'auto' :'85px' }}>
      <Container maxWidth="xl" sx={{ overflow: 'hidden', padding: isMobile ? '0 16px' : '0 24px' }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="logo" style={{ width: '100%', height: isMobile ? '45px': '80px', padding: !isMobile ? '30px': 0 }} />
            </Link>
          </Grid>
          {isMobile ? (
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <IconButton onClick={toggleDrawer(true)}>
                  <MenuIcon />
                </IconButton>
              </Box>
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                  className={classes.fullList}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}

                >
                  <Box sx={{ boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', height: '80px', borderColor: '#FFFFFF', borderRadius: 2, display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 0 }}>
                    <IconButton onClick={toggleDrawer(true)}>
                      <img src={logo} alt="logo" style={{ width: '100%', height: '45px', marginLeft: '20px' }} />
                    </IconButton>
                    <IconButton onClick={toggleDrawer(true)} sx={{ margin: '20px' }}>
                      <CloseIcon color={'action'} />
                    </IconButton>
                  </Box>
                  <List sx={{ margin: '10px', width: '95%' }}>
                    {menuItemsMobile.map((item, index) => (
                      <>
                          <Link key={index} to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItem button >
                              <ListItemText primary={item.name} sx={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: 400, lineHeight: '22.4px', color: '#333333', marginTop: '16px' }} />
                              <ArrowForwardIosIcon />
                            </ListItem>
                          </Link>
                        {/* </>)} */}
                      </>
                    ))}
                  </List>
                  <Box textAlign="center" marginTop={10}>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: primaryColor, // Replace with your primary color
                        ':hover': {
                          backgroundColor: primaryColor, // Adjust the hover color
                        },
                        height: '55px',
                        width: '165px',
                      }}
                      onClick={() => dispatch(handleSignupModal(true))}
                    >
                      Donate Now
                    </Button>
                    <div className={classes.contactInfo}>
                      <div className={classes.contactDetails}>
                        <Typography>📞 7506681015</Typography>
                        <Typography>
                          <MailIcon style={{ color: 'grey', verticalAlign: 'middle', marginRight: 4 }} /> {/* Add the Mail icon with grey color */}
                          info@yoursit.com
                        </Typography>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Drawer>
            </Grid>
          ) : (
            <>
              <Grid item xs sm container alignItems="center" spacing={10} sx={{ flexWrap: 'nowrap' }}>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Box
                      bgcolor="#F8F8F8"
                      height={49}
                      width={49}
                      borderRadius="50%"
                      mr={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img src={mailIcon} alt="mail icon" style={{ maxWidth: '100%', height: 'auto' }} />
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" fontWeight={700}>
                        Mail:
                      </Typography>
                      <Typography variant="body1" color="#BABABA">
                        cleanyourcloset122@gmail.com
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Box
                      bgcolor="#F8F8F8"
                      height={49}
                      width={49}
                      borderRadius="50%"
                      mr={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img src={phoneIcon} alt="phone icon" style={{ maxWidth: '100%', height: 'auto' }} />
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" fontWeight={700}>
                        Call Us:
                      </Typography>
                      <Typography variant="body1" color="#BABABA">
                        7506681015
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs="auto">
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    backgroundColor: primaryColor, // Replace with your primary color
                    ':hover': {
                      backgroundColor: primaryColor, // Adjust the hover color
                    },
                    height: '55px',
                    width: '165px',
                  }}
                  onClick={() => dispatch(handleSignupModal(true))}
                >
                  Donate Now
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactBar;

import './contact.css'
import { primaryColor } from '../../Styles/styles'
import { Button, Grid, TextField, useMediaQuery, useTheme } from '@mui/material'
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { handleThankYouModal } from '../../redux'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import MobcontactBanner from '../../assets/MobileImages/MobcontactBanner.png'
import contactBanner from '../../assets/images/contactBanner.png'


const ContactUs = () => {
    const dispatch = useDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [subject, setSubject] = useState('')
    const [description, setDescription] = useState('')
    const [error, setError] = useState({
        state: false,
        value: ''
    })

    const reset = () => {
        setName('')
        setEmail('')
        setPhoneNumber('')
        setSubject('')
        setDescription('')

    }
    const handleData = async () => {
        try {
            const result = validateEmail(email)
            if (!name || name?.length === 0) {
                setError({
                    state: true,
                    value: 'Name is required'
                })
                return
            }
            else if (!result) {
                setError({
                    state: true,
                    value: 'Please enter a valid email'
                })
                return
            }
            setError({
                state: false,
                value: ''
            })
            const formdata = {
                "entry.1600021043": name,
                "entry.912509692": email,
                "entry.875221847": phoneNumber,
                "entry.499996859": subject,
                "entry.1601082235": description
            }
            await axios.post(`https://cricketspin.rewardzpromo.com/api/submitForm/message`, formdata)
            reset()
            dispatch(handleThankYouModal({ state: true, formName: 'message' }))

        } catch (error) {
            console.log("🚀 ~ handleData ~ error:", error)
        }

    }

    const validateEmail = (email: string) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    // useEffect(() => {
    //     if (error) {
    //         setTimeout(() => {
    //             setError({
    //                 state: false,
    //                 value: ''
    //             })
    //         }, 5000)
    //     }
    // }, [error])
    return (

        <div className='contact-parent-section'>
            <div className='contact-section-1'>
                <img src={ isMobile ? MobcontactBanner: contactBanner} width={'276px'} alt="Background" className="responsive-image" />

                <div className="about-overlay">
                    <div className='contact-header'>
                        Contact
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#FFFFFF' }}>
                                <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} to={"/"}>
                                    Home
                                </Link>
                                <Link
                                    // underline="hover"
                                    style={{ color: '#FFFFFF', textDecoration: 'none' }}
                                    color="inherit"
                                    to={"/contactus"}
                                >
                                    Contact
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact-section'>
                <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '770px', marginTop: '-4%' }}>
                    {/* <div className='contact-image' /> */}
                    <div style={{marginLeft: '20px', marginRight: '20px'}}>

                    <div className='contact-header'>Send a
                        <span style={{ color: primaryColor }}> Message</span>
                    </div>
                    <div className='HorizontalLine' />
                    <div className='content'>
                        <br />
                        Need assistance or have thoughts to share? Contact us and let's chat.
                    </div>
                    </div>
                    <form style={{ margin: isMobile ? '20px': 0 }}> 
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <div className='contact-content'>
                                    Name
                                </div>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        marginTop: '2%',
                                        '& .MuiFormHelperText-root': { color: 'red' },
                                    }}
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    helperText={error.value.startsWith('Name') ? error.value : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className='contact-content'>
                                    Email
                                </div>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        marginTop: '2%',
                                        '& .MuiFormHelperText-root': { color: 'red' },
                                    }}
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    helperText={error.value.startsWith('Please') ? error.value : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className='contact-content'>
                                    Subject
                                </div>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        marginTop: '2%',
                                        '& .MuiFormHelperText-root': { color: 'red' },
                                    }}
                                    value={subject}
                                    onChange={(event) => setSubject(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className='contact-content'>
                                    Phone Number
                                </div>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        marginTop: '2%',
                                        '& .MuiFormHelperText-root': { color: 'red' },
                                    }}
                                    value={phoneNumber}
                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className='contact-content'>
                                    Your Message
                                </div>
                                <TextField
                                    multiline
                                    rows={6} 
                                    sx={{width: '370px',
                                        marginTop: '2%',
                                        '& .MuiFormHelperText-root': { color: 'red' },
                                    }}
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                    <Button onClick={handleData} size="small" variant="contained" sx={{ backgroundColor: primaryColor, borderColor: primaryColor, ":hover": { backgroundColor: primaryColor }, height: '55px', width: '187px', marginTop: '2%', marginBottom: '2%' }}>SEND MESSAGE</Button>
                    </form>
                    <br /><br />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <div className='contact-footer-desc'>
                        <div className='contact-footer-header'>
                            Contact Info
                        </div>
                        {/* <div className='contact-screen-options'>
                            <div className='contact-footer-who-we-are'><div className='contact-footer-address-images'></div><div style={{ marginLeft: '10px', marginTop: '3%',fontFamily:'Roboto' }}>99 S.t Jomblo Park Pekanbaru 28292. Indonesia</div></div>
                        </div> */}
                        <div className='contact-screen-options'>
                            <div className='contact-footer-who-we-are'><div className='contact-footer-phoneIcon-images'></div><div style={{ marginLeft: '10px', marginTop: '4%', fontFamily: 'Roboto' }}>7506681015</div></div>
                        </div>
                        <div className='contact-screen-options'>
                            <div className='contact-footer-who-we-are'><div className='contact-footer-mail-images'></div><div style={{ marginLeft: '10px', marginTop: '4%', fontFamily: 'Roboto' }}>cleanyourcloset122@gmail.com</div></div>
                        </div>

                    </div>
                    {/* <Card sx={{ maxWidth: '370px', minWidth: '275px', maxHeight: '390px', backgroundColor: primaryColor, padding: '20px', color: '#FFFFFF' }}>
                        <CardContent>
                            <img src={mic} height={'69px'} width={'69px'} alt='mic'/><br /><br />
                            <Typography variant="h6" component="div">
                                Become a Volunteer
                            </Typography>
                            <Typography sx={{ mb: 1.5, fontSize: '16px' }}>
                                We need you now for world
                                <br /><br />
                                Take action and volunteer with us. Together, we can make a meaningful difference in the world.
                            </Typography>

                        </CardContent>
                        <CardActions>
                            <Button size="small" variant="contained" sx={{ backgroundColor: '#333333', ":hover": { backgroundColor: '#333333' }, height: '55px', width: '187px' }} onClick={()=>dispatch(handleJoinUsModal(true))} >JOIN US NOW</Button>
                        </CardActions>
                    </Card><br /><br /> */}
                </div>
            </div>
            <div>
            </div>
        </div>
    )
}

export default ContactUs
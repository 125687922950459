import { Button, Modal, TextField, Select, MenuItem, Grid, useTheme, useMediaQuery, FormControl, Typography, Slide, Box } from '@mui/material';
import '../DonorSignupForm/donorsignupform.css';

import axios from 'axios';
import { primaryColor } from '../../Styles/styles';
import { useDispatch } from 'react-redux';
import { handleCorporateParterModal, handleThankYouModal } from '../../redux';
import { useSelector } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import close from '../../assets/svg/close.svg';

const CorporatePartnerForm = () => {
    
    const store: any = useSelector((store: any) => store.handleCorporateParterModal);
    const dispatch = useDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    
    const Formbody = () => {
        const initialValues = {
            name: '',
            phoneNumber: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            state: '',
            // city: '',
            zipcode: '',
            industry: '',
            personName: '',
            role: '',
            collaborateDesc: '',
            communicationMethod: '',
            notes: '',
        };

        const validationSchema = Yup.object().shape({
            name: Yup.string().required('Full Name is required'),
            industry: Yup.string().max(50, 'Industry name must be at most 100 characters').required('This field is required'),
            personName: Yup.string().required('Person Name is required'),
            role: Yup.string().required('Role is required'),
            email: Yup.string()
                .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    'Invalid email'
                )
                .required('Email is required'),
            phoneNumber:  Yup.string().matches(/^[0-9]{10}$/, 'Invalid mobile number format').required('Phone Number is required'),
            addressLine1: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            addressLine2: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            state: Yup.string().max(50, 'State name must be at most 50 characters').required('State is required'),
            zipcode: Yup.string().length(6, 'Zip code must be of 6 characters').matches(/^\d+$/, 'Zip code must contain only numbers').required('Zip code is required'),
            collaborateDesc: Yup.string().required('Please select atleast one'),
            communicationMethod: Yup.string().required('Please select atleast one'),
            notes: Yup.string().max(500, 'Max 500 characters')
            
        });
        
        const handleSubmit = async (values: any) => {

            try {
                const formdata = {

                    "entry.1948923765": values.name,
                    "entry.1274609356" :values.industry,
                    "entry.933521587": values.industry,
                    "entry.1470408603": values.personName,
                    "entry.1496652262": values.role,
                    "entry.1404313487": values.email,
                    "entry.1145298447": values.phoneNumber,
                    "entry.667196702": values.addressLine1,
                    "entry.1176459477": values.addressLine2,
                    "entry.1322802031": values.state,
                    "entry.1134076088": values.zipcode,
                    "entry.184459628": values.collaborateDesc,
                    "entry.1801706540": values.communicationMethod,
                    "entry.72878217": values.notes
                }

                await axios.post(`https://cricketspin.rewardzpromo.com/api/submitForm/CorporatePartnerSignup`, formdata)
                dispatch(handleCorporateParterModal(false))
                dispatch(handleThankYouModal({ state: true, formName: 'corporatePartner' }))
            } catch (error) {
                console.log("🚀 ~ handleSubmit ~ error:", error)

            }

        }
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {( formik ) => (
                    
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container className='form-donate-now' sx={{ width: isMobile ? '100%' : '942px' }}>
                            <div style={{ width: '100%' }}>
                                <div className='form-footer-header'>
                                    Corporate Signup
                                    <img src={close} alt="logo" onClick={handleClose} />
                                </div>
                                <div className='form-horizontal-line' />
                                <div className='form-message'>We're excited about your company's interest in partnering with CYC to support our clothing donation initiative. Your involvement can make a substantial impact on our community and the environment. Please fill out the details below to start our partnership.</div>
                            </div>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Company Name
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="name" />}

                                // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Industry/Sector
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="industry"
                                    name="industry"
                                    value={formik.values.industry}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="industry" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                Contact Person Name
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="personName"
                                    name="personName"
                                    value={formik.values.personName}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="personName" />}

                                // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Email Address
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    type='email'
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="email" />}

                                    // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Phone Number
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="phoneNumber" />}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Position/Role in the Company
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    type='role'
                                    id="role"
                                    name="role"
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="role" />}

                                    // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Company Address Line 1
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine1"
                                    name="addressLine1"
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine1" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Company Address Line 2
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine2"
                                    name="addressLine2"
                                    value={formik.values.addressLine2}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine2" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    State
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="state"
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="state" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Zip Code
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="zipcode"
                                    name="zipcode"
                                    value={formik.values.zipcode}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="zipcode" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <div className='form-content' style={{ marginTop: '3%'}}>
                                How would you like to collaborate with CYC ? (Select all that apply)
                                </div>
                                <FormControl fullWidth error={Boolean(formik.touched.collaborateDesc && formik.errors.collaborateDesc)}>
                                    <Select
                                        className='form-textfield'
                                        sx={{
                                            marginTop: '1%',
                                            width: isMobile ? '340px' : '765px',
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' },
                                            '& .MuiFormHelperText-root': { color: 'red' },
                                        }}
                                        id="collaborateDesc"
                                        name="collaborateDesc"
                                        value={formik.values.collaborateDesc}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <MenuItem value="Organize donation drive">Organize donation drive</MenuItem>
                                        <MenuItem value="Sponsorship">Sponsorship</MenuItem>
                                        <MenuItem value="Marketing support">Marketing support</MenuItem>
                                        <MenuItem value="Engage employees in donations">Engage employees in donations</MenuItem>
                                        <MenuItem value="Monetary donations for operations">Monetary donations for operations</MenuItem>
                                    </Select>
                                    {formik.touched.collaborateDesc && formik.errors.collaborateDesc ? (
                                        <Typography style={{ fontSize: '12px', margin: 2 }} sx={{ color: 'red' }}>
                                            {formik.errors.collaborateDesc}
                                        </Typography>
                                    ) : null}
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} >
                                <div className='form-content' style={{ marginTop: '3%'}}>
                                    Preferred Method of Communication
                                </div>
                                <FormControl fullWidth error={Boolean(formik.touched.communicationMethod && formik.errors.communicationMethod)}>
                                    <Select
                                        className='form-textfield'
                                        sx={{
                                            marginTop: '1%',
                                            width: isMobile ? '340px' : '765px',
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' },
                                            '& .MuiFormHelperText-root': { color: 'red' },
                                        }}
                                        id="communicationMethod"
                                        name="communicationMethod"
                                        value={formik.values.communicationMethod}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <MenuItem value="Email">Email</MenuItem>
                                        <MenuItem value="Phone">Phone</MenuItem>
                                        <MenuItem value="In-Person">In-Person</MenuItem>
                                        <MenuItem value="Meeting">Meeting</MenuItem>
                                    </Select>
                                    {formik.touched.communicationMethod && formik.errors.communicationMethod ? (
                                        <Typography style={{ fontSize: '12px', margin: 2 }} sx={{ color: 'red' }}>
                                            {formik.errors.communicationMethod}
                                        </Typography>
                                    ) : null}
                                </FormControl>

                            </Grid>

                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Additional Notes
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ height: '170px', marginBottom: isMobile? '30%' : '10%', marginTop: '2%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="notes"
                                    name="notes"
                                    multiline
                                    rows={6}
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="notes" />}
                                    fullWidth
                                /><br /><br /><br />
                                <div className='box-button'>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            height: '55px',
                                            width: '187px',
                                            fontSize: '16px',
                                            marginLeft: isMobile ? '100px' : '300px',
                                            backgroundColor: primaryColor,
                                            ":hover": { backgroundColor: primaryColor },
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    };

    const handleClose = () => {
        dispatch(handleCorporateParterModal(false))
    };


    return (
        <Modal
            open={store}
            onClose={() => dispatch(handleCorporateParterModal(false))}
            style={{ marginTop: isMobile ? '20%' : '5vh' }}
        >
            <Slide direction="up" in={store} mountOnEnter unmountOnExit>
                <Box
                    className="modal"
                    sx={{
                        borderRadius: '8px',
                    }}
                >
                    <Formbody />
                </Box>
            </Slide>
        </Modal>
    )
}

export default CorporatePartnerForm